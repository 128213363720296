import axios from 'axios';
import { getToken } from '@/utils/auth';
import mockConfig from '@/utils/mock';
import { Message } from 'ant-design-vue';
import store from '../store';

let baseApi = '';
if (process.env.SERVER_ENV === 'prod') {
  baseApi = 'http://116.62.93.84:8080';
} else if (process.env.SERVER_ENV === 'test') {
  baseApi = 'http://dev.caocaovip.com:8082';
} else {
  baseApi = process.env.VUE_APP_BASE_API;
}

// 创建axios实例
const service = axios.create({
  baseURL: baseApi, // api 的 base_url
  timeout: 5000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers.token = `Bearer ${getToken()}`;
    }

    if (config.data) {
      Object.keys(config.data).forEach((n) => {
        if (config.data[n] === '') {
          delete config.data[n];
        }
      });
    }

    if (process.env.NODE_ENV === 'development') {
      const mockItem = mockConfig.mockList.find((n) => n.url === config.url);
      if (mockItem && mockItem.open) {
        config.baseURL = mockConfig.baseURL;
      }
    }

    return config;
  },
  (error) => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  },
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (!res.success && res.errorCode === 200003) {
      Message.confirm(
        res.message,
        '安全提示',
        {
          confirmButtonText: '重新登录',
          type: 'warning',
        },
      ).then(() => {
        store.dispatch('FedLogOut').then(() => {
          window.location.reload();
        });
      });
    }

    return res;
  },
  (error) => {
    console.log(`err${error}`); // for debug
    return {
      success: false,
      errorCode: 500000,
      message: '请求服务器异常',
    };
  },
);

export default service;
